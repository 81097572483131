import {
    HomePageRandomJoke,
    WelcomeTitle,
    HeaderWorldClock,
    ClockConfig,
    HomePageStarredEntities,
    CustomHomepageGrid,
    HomePageToolkit,
    HomePageCompanyLogo,
  } from '@backstage/plugin-home';
  import { Content, Header, Page } from '@backstage/core-components';
  import { HomePageSearchBar } from '@backstage/plugin-search';
  import React from 'react';
  import HomeIcon from '@material-ui/icons/Home';
  
  const clockConfigs: ClockConfig[] = [
    {
      label: 'NYC',
      timeZone: 'America/New_York',
    },
    {
      label: 'UTC',
      timeZone: 'UTC',
    },
    {
      label: 'STO',
      timeZone: 'Europe/Stockholm',
    },
    {
      label: 'TYO',
      timeZone: 'Asia/Tokyo',
    },
  ];
  
  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  
  const defaultConfig = [
    {
      component: 'CompanyLogo',
      x: 0,
      y: 0,
      width: 12,
      height: 1,
    },
    {
      component: 'WelcomeTitle',
      x: 0,
      y: 1,
      width: 12,
      height: 1,
    },
    {
      component: 'HomePageSearchBar',
      x: 0,
      y: 2,
      width: 12,
      height: 1,
    },
  ];
  
  export const homePage = (
    <Page themeId="home">
      <Header title={<WelcomeTitle />} pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>
      <Content>
        <CustomHomepageGrid config={defaultConfig}>
          <HomePageSearchBar />
          <HomePageRandomJoke />
          <HomePageStarredEntities />
          <HomePageCompanyLogo />
          <WelcomeTitle />
          <HomePageToolkit
            tools={[
              {
                url: 'https://backstage.io',
                label: 'Backstage Homepage',
                icon: <HomeIcon />,
              },
            ]}
          />
        </CustomHomepageGrid>
      </Content>
    </Page>
  );